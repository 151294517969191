/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */
import React from 'react'
import { Box, Loader } from '@opencrvs/components'
import styled from 'styled-components'

const Container = styled(Box)`
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
`
interface FormLoaderProps {
  loadingText: string
  id: string
}

export function FormLoader(props: FormLoaderProps) {
  const { loadingText, id } = props
  return (
    <Container>
      <Loader
        id={id}
        loadingText={loadingText}
        marginPercent={5}
        flexDirection="column-reverse"
      />
    </Container>
  )
}
