/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */

import { useSelector } from 'react-redux'
import {
  LanguageConfig,
  CertificateTemplateConfig
} from '@opencrvs/commons/client'
import { getLanguage, getCertificateTemplates } from '@client/offline/selectors'

interface ApplicationConfig {
  certificateTemplates: CertificateTemplateConfig[]
  language?: LanguageConfig
}

export const useAppConfig = (): ApplicationConfig => {
  return {
    language: useSelector(getLanguage),
    certificateTemplates: useSelector(getCertificateTemplates)
  }
}
